import React from "react";

import { Container } from "./Banner.style";

const Banner = () => (
  <Container>
    <object
      type="image/svg+xml"
      data="PulseBanner_1_0.svg"
      aria-label="pulse banner"
    />
  </Container>
);

export default Banner;
