import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import isMobile from "../isMobile";

const Logo = styled.img`
  height: 70px;
  @media (max-width: 768px) {
    height: 50px;
  }
`;

const Nav = styled.ul`
  margin: 0
  list-style-type: none;
  color: #1e233a;
  background: #f7f7f7;
  padding: 5px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  & li {
    width: 60px;
    text-align: center;
    position: relative;
  }
`;

const Beam = styled.div`
  content: "";
  background-color: white;
  position: absolute;
  opacity: 0;
  box-shadow: 0 0 4px 2px white;
  height: 1px;
  width: 60px;
  top: 34px;
  transition: opacity 0.15s;
  z-index: 999;
`;

const Item = styled(NavLink)`
  color: #1e233a;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #30385f !important;
  }
  &:hover + div {
    opacity: 1;
  }
`;

const Header = () => {
  return (
    <div>
      <Nav>
        <NavLink to="/">
          <Logo src="images/pulse_logo_text.png" alt="pulse logo" />
        </NavLink>
        {!isMobile() && (
          <li>
            <Item exact className="tab" to="/">
              Home
            </Item>
            <Beam />
          </li>
        )}
        <li>
          <Item className="tab" to="/services">
            Services
          </Item>
          <Beam />
        </li>
        <li>
          <Item className="tab" to="/projects">
            Projects
          </Item>
          <Beam />
        </li>
        <li>
          <Item className="tab" to="/contact">
            Contact
          </Item>
          <Beam />
        </li>
      </Nav>
    </div>
  );
};

export default Header;
