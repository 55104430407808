import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  padding: 0px 5px;
  display: flex;
  justify-content: flex-end;
  font-size: 0.8em; }
  @media (max-width: 460px) {
      padding: 0px 10px;
      font-size: 0.7em; }
  & p {
    padding: 0 10px;
  }
`;

const Footer = () => {
  return (
    <Container>
      <p>Pulse Networks Limited Est. 1998</p>
    </Container>
  );
};

export default Footer;
