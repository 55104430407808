import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 149px;
  overflow: hidden;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  @media (max-width: 768px) {
    margin-left: -220px;
  }
`;
